<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important
.d-label
  font-weight: 600
  font-size: 0.9rem
  color: #555
.btn-suggestion
  // &:nth-child(2n-1)
  //   opacity: 0.5
  //   // background-color: #fff
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-center(style='min-height: 60vh')
    .col-10
      .mb-4
      h3.title.py-3 수기 입력하기
        router-link.btn.btn-light.float-right(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}/start-from-excel`') 엑셀로 입력하기
      //- p(v-show='error')
      //-   h3.subtitle.pb-3 {{error}}
      //- pre {{cols}}
      div.async(:class='{done: done}')
        div.p-1(type='text' contenteditable @paste='paste_input')
        small: strong {{paste_message}}
        //- pre {{paste_items}}
        form.form(@submit.prevent='submit()')
          .mb-4
          //- pre {{show_suggest}}
          .form-group(v-for='(col, $index) in cols')
            template(v-if='col.format == `text`')
              label.d-block {{col.label}}
              input.form-control(type='text' v-model='col.value' :autofocus='$index===0' :ref='`Input`+$index' @focus='open_suggestion($index)' @blur='close_suggestion($index)')
              p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre' :autofocus='$index===0' :ref='`Input`+$index') {{col.description}}
            template(v-else-if='col.format == `check`')
              label.d-block {{col.label}}
              b-form-checkbox.rounded-0(v-model='col.value' value='Y' unchecked-value='N' :autofocus='$index===0' :ref='`Input`+$index') {{col.description}}
            template(v-else-if='col.format == `textarea`')
              label.d-block {{col.label}}
              textarea.form-control(v-model='col.value' rows=10)
              p.text-muted.mb-0.text-left(style='font-weight: normal; white-space: pre' :autofocus='$index===0' :ref='`Input`+$index') {{col.description}}
            template(v-else)
              label.d-block {{col.label}}
              input.form-control(type='text' v-model='col.value' :autofocus='$index===0' :ref='`Input`+$index' @focus='open_suggestion($index)' @blur='close_suggestion($index)')
              small.text-muted(v-if='col.format==`@고객아이디`') 빈칸이면 자동으로 발급됩니다.
              small.text-muted(v-if='col.format==`@고객연락처`') 문자 발송 시 이용합니다
              small.text-muted(v-if='col.format==`@고객이메일`') 이메일 발송 시 이용합니다.

            div(v-if='show_suggest[$index]')
              button.btn.btn-light.mr-1.btn-sm.p-2.mt-1.btn-suggestion(v-for='item in paste_items' type='button' @click.prevent='fill_suggestion($index, item)') {{item}}

          .form-group
            .mb-4
            span.d-label 추가 항목이 필요하신가요?
              //- router-link.btn.btn-light.text-primary.ml-4(target='_blank' :to='`/property/${property_id}/customer/${document_id}/start-from-scratch/custom-field`') 항목 관리 열기
              button.btn.btn-light.text-primary.ml-4(type='button' @click='$modal.show(`table-config`)') 항목 관리 열기
            //- pre {{cols}}
          .form-group
            .mb-4


          .clearfix
          .mb-4
          .form-group
            //- button.btn.btn-default.text-danger.float-left(type='button' @click='remove()') 삭제
            //- button.btn.btn-primary.float-right(type='button' @click='submit_view()' :disabled='saving') {{saved_view_text}}
            button.btn.btn-primary.float-right.mr-2(type='submit' :disabled='saving') {{saved_text}}
            //- button.btn.btn-light.text-primary.float-right.mr-2(type='button' @click='submit_bulk()' :disabled='saving') {{saved_text}}
            //- router-link.btn.btn-light.text-secondary.float-left.mr-2(:to='`/property/${property_id}/customer/${document_id}`') 조회로 가기
            .py-3.pr-4.float-right
              b-form-checkbox.rounded-0(v-model='keep_next' value='Y' unchecked-value='N') 저장하고 계속 입력
            .clearfix
            .pb-4
      .clearfix
      .mb-4
  modal.modal-default.py-2(name='table-config' :width='`90%`' :height='`auto`' :scrollable='true' :clickToClose='false')
    .content
      .title 항목 관리
        small.text-muted.ml-2 {{document.name}}
      .mb-4
      customer-table-config(:property_id='property_id' :document_id='document_id')
        template(v-slot:back)
          button.btn.btn-light.text-secondary.mr-2(type='button' @click='$modal.hide(`table-config`)') 닫기

</template>

<script>

import CustomerTableConfig from '@/components/CustomerTableConfig'
import moment from 'moment'
import {compact, uniq} from 'lodash'

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'saved_filter_id'],
  components: {
    CustomerTableConfig,
  },
  computed: {
    saved_filter_prefix() {
      return this.saved_filter_id ? '-'+this.saved_filter_id : ''
    },
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      saved_text: '저장',
      document: {},
      error: '',

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      form: {
        customer_id: 'Y',
        customer_name: 'Y',
        customer_phone: 'Y',
        customer_email: 'Y',
      },

      last_col_id: 10,

      cols: [

      ],

      should_save_cols: false,
      keep_next: 'N',

      paste: '',
      paste_items: [],
      // paste_items: ['a', 'b'],

      show_suggest: {},
      paste_message: '',
    }
  },
  async mounted() {
    if (this.$store.state.documents) {
      this.load()
    }
    // this.keep_next = 'Y'
  },
  methods: {
    fill_suggestion(i, v) {
      this.cols[i].value = v
    },
    open_suggestion(i) {
      this.$set(this.show_suggest, i, true)
    },
    close_suggestion(i) {
      setTimeout(() => {
        this.$set(this.show_suggest, i, false)
      }, 300);
    },
    paste_input(e) {
      // if (e.srcElement.id != 'Input0') return
      // setTimeout(() => {
      //   const v = e.srcElement.value
      //   console.log(v)
      // }, 300);
      setTimeout(() => {
        // console.log(e.srcElement.getElementsByTagName('td'))
        const paste_items = []
        const extract = (el) => {
          console.log(el)
          for(const e of el) {
            if (e.children && e.children.length) continue
            if (e.textContent.includes('\t') || e.textContent.includes('\n') || e.textContent.includes(':')) {
              e.textContent.split(/\n|\t|\:/g).map(text => {
                paste_items.push(text.trim())
              })
            } else {
              paste_items.push(e.textContent.trim())
            }
          }
        }
        // extract(e.srcElement.querySelectorAll('td,li,div,a,dt,dd,span,strong'))
        extract(e.srcElement.querySelectorAll('*'))
        this.paste_items = compact(uniq(this.paste_items.concat(paste_items)))
        this.paste_message = this.paste_items.length + '개 가져왔습니다.'
        e.srcElement.innerHTML = ''
      }, 300);
    },
    del_col(col) {
      this.cols = this.cols.filter(e => {
        return e.key != col.key
      })
    },
    load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }
      const prev_cols = this.cols.slice()
      const prev_cols_by_key = {}
      for (const col of prev_cols) {
        prev_cols_by_key[col.key] = col
      }
      console.log('>>', prev_cols_by_key)
      this.cols = this.document.config.cols.slice()

      // if (this.cols.length === 0) {
      //   this.should_save_cols = true
      //   // 기본으로 채워넣음
      //   this.cols = [
      //     {
      //       key: 'd0',
      //       label: '고객 이름',
      //       format: '@고객이름',
      //       visible_at_table: 'Y',
      //       editable: false,
      //       value: '',
      //     },
      //     {
      //       key: 'd1',
      //       label: '고객 이메일 주소',
      //       format: '@고객이메일',
      //       visible_at_table: 'Y',
      //       editable: false,
      //       value: '',
      //     },
      //     {
      //       key: 'd2',
      //       label: '고객 연락처',
      //       description: '고객 연락처 (휴대폰)',
      //       format: '@고객연락처',
      //       visible_at_table: 'Y',
      //       editable: false,
      //       value: '',
      //     },
      //     {
      //       key: 'd3',
      //       label: '고객 아이디',
      //       description: '고객 아이디 (고유식별번호)',
      //       format: '@고객아이디',
      //       visible_at_table: 'Y',
      //       editable: false,
      //       value: '',
      //     },
      //   ]
      // } else {
      //   this.should_save_cols = false
      // }

      for (const col of this.cols) {
        const f = prev_cols_by_key[col.key]
        if (f) {
          col.value = f.value
        }
      }

      this.done = true
    },
    async submit_bulk() {
      this.submit(true)
    },
    async submit_view() {
      this.submit(false)
    },
    async submit(keep_next = true) {
      try {
        this.saving = true

        this.saved_text = '저장 중'

        // if (this.should_save_cols) {
        //   const document = Object.assign({}, this.document, {
        //     config: Object.assign({}, this.document.config, {
        //       cols: [].concat(this.cols).map(e => {
        //         e.value = undefined
        //         return e
        //       }),
        //       last_col_id: this.last_col_id,
        //     })
        //   })
        //   const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        //   if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패 (고객데이터 추가전에 해당목록 항목을 먼저 저장이 필요함)')

        //   this.should_save_cols = false
        // }

        const row = {}
        let has_content = false
        for (const col of this.cols) {
          row[col.key] = String(col.value || '')
          if (row[col.key]) has_content = true
        }
        if (!has_content) throw new Error('내용을 입력해주세요')

        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
          row,
        })
        if (r?.data?.message != 'ok') throw new Error('고객 저장 실패. ' + r.data.message)
        const row_id = r.data.row_id
        const external_id = r.data.external_id

        // // await this.$store.dispatch('documents', this.property_id)

        this.saved_text = '저장됨'

        this.cols = this.cols.map(e => {
          e.value = ''
          return e
        })

        if (this.keep_next == 'Y') {
          // this.load()
          // console.log(this.$refs.Input0)
          // this.$refs['Input0']?.focus()
          if (this.$refs.Input0 && this.$refs.Input0[0]) {
            this.$refs.Input0[0].focus()
          }
          const sample = Object.values(row).join(' ').slice(0, 30)
          this.$bvToast.toast(`[${moment().format('HH:mm:ss')}] 저장했습니다: ${sample}`, {
            title: `알림`,
            variant: 'light',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeReadableText(this.document.name)}/${row_id}/${external_id}`,
          })
        }
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '저장하고 조회'
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
    // async remove() {
    //   try {
    //     if(!confirm('정말로 삭제하시나요?')) return true

    //     const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/config`)
    //     if (r?.data?.message != 'ok') throw new Error('고객목록 삭제 실패')

    //     await this.$store.dispatch('documents', this.property_id)

    //     this.$modal.show('dialog', {
    //       title: '알림',
    //       text: '삭제했습니다.',
    //     })
    //     this.$router.push({
    //       path: `/property/${this.property_id}/customer`,
    //     })
    //   } catch (error) {
    //     this.$modal.show('dialog', {
    //       title: '알림',
    //       text: error.message,
    //     })
    //   }
    // },
  },
}
</script>
